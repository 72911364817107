<template>
    <div class="app-body">
        <div class="a-flex-rsbc">
            <bread-crumb></bread-crumb>
            <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="editElectric">编辑</el-button>
        </div>
        <el-card class="el-main">
            <el-form :model="form" ref="form" label-width="145px" label-position="left">
                <el-form-item label="电费项目名称" prop="name" >
                    <span>{{ form.name }}</span>
                </el-form-item>
                <el-form-item label="电费缴纳方" prop="cycle" >
                    <span>{{ form.chargePayeeType | initDic(chargePayeeTypeDic) }}</span>
                </el-form-item>
                <el-form-item label="电费价格" prop="name" >
                    <span>￥{{ util.numFormat(form.unitPrice) }}</span>
                </el-form-item>
                <el-form-item label="电费收取方式" prop="chargeFeeType" >
                    <span>{{ form.chargeFeeType | initDic(chargeFeeTypeDic) }}</span>
                </el-form-item>
                <el-form-item label="电费收款方" prop="cycle" >
                    <span>{{ form.chargePayeeType | initDic(chargePayeeTypeDic) }}</span>
                </el-form-item>
                <el-form-item label="收款物业方" prop="date" >
                    <div class="a-flex-cfsfs" >
                        <span v-for="(item, index) in form.propertyList" :key="index">{{ item.propertyName }}，占比{{ util.numFormat(item.rate) }}%</span>
                    </div>
                </el-form-item>
                <el-form-item label="发票规则" prop="cycle" >
                    <span>{{ form.chargePayeeType | initDic(chargePayeeTypeDic) }}</span>
                </el-form-item>
                <div class="a-fs-14 a-fw-700 a-flex-rfsc" style="margin: 36px 0 16px">
                    <span>关联站点</span>
                </div>
                <el-table 
                    :border='true' 
                    :data="stationAmmeterList" 
                    :span-method='mergeColsStation'
                    style="width: 800px;">
                    <el-table-column prop="stationId" label="站点名称" >
                        <template slot-scope="{ row }">
                            <span>{{ row.stationName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ammeterId" label="电表号">
                        <template slot-scope="{ row }">
                            <span>{{ row.meterNumber || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ratioId" label="电表设备占比">
                        <template slot-scope="{ row }">
                            <span>{{ (row.ratio*100).toFixed(2) }}%</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="a-fs-14 a-fw-700 a-flex-rfsc" style="margin: 36px 0 16px">
                    <span>历史修改记录</span>
                </div>
                <el-table 
                    :data="tableData" 
                    :highlight-current-row="true" 
                    :span-method='mergeCols'
                    :border='true'
                    style="width: 100%">
                    <el-table-column prop="nameId" label="电费项目名称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.name || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="chargeFeeTypeId" label="电费收取方式" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.chargeFeeType | initDic(chargeFeeTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="chargePayeeTypeId" label="电费收款方" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.chargePayeeType | initDic(chargePayeeTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="propertyListId" label="收款物业方" min-width="120">
                        <template slot-scope="{ row }">
                            <span v-if="row.propertyList.length == 1 && !row.propertyList[0] || !row.propertyList.length">-</span>
                            <div class="a-flex-rfsc a-flex-wrap" v-else>
                                <span class="stationTag" v-for="(item,index) in row.propertyList" :key="index">
                                    {{ item.propertyName?item.propertyName:'-' }}，占比{{ item.rate/100 }}%
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="unitPriceId" label="电费（/元/kwh）" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.unitPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationId" label="站点" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ammeterId" label="电表号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.meterNumber || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ratioId" label="电表设备占比">
                        <template slot-scope="{ row }">
                            <span>{{ (row.ratio*100).toFixed(2) }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pid" label="创建日期" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ $getDay.getTimeStampToStr(row.updateTime) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="height: 100px;"></div>
            </el-form>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="cancelSubmit">关闭</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import util from '../../../src/utils/util'
export default {
    data () {
        return {
            util: util,
            id: '',
            editType: '',
            form: {},
            tableData: [],
            stationAmmeterList: [],
            chargeFeeTypeDic: [],
            chargePayeeTypeDic: []
        }
    },
    computed: {
        // 获取所有单元格合并数据
        spanArr() {
            if(!this.tableData.length) return []
            const mergeCols = ['nameId','chargeFeeTypeId','chargePayeeTypeId','propertyListId','unitPriceId','stationId','ammeterId','ratioId','pid'] // 需要合并的列（字段）
            const tableColumn = ['nameId','chargeFeeTypeId','chargePayeeTypeId','propertyListId','unitPriceId','stationId','ammeterId','ratioId','pid']
            return util.getMergeCells(this.tableData, tableColumn, mergeCols)
        },
        spanArrStation () {
            if(!this.stationAmmeterList.length) return []
            const mergeCols = ['stationId','ammeterId','ratioId','pid'] // 需要合并的列（字段）
            const tableColumn = ['stationId','ammeterId','ratioId','pid']
            return util.getMergeCells(this.stationAmmeterList, tableColumn, mergeCols)
        }
    },
    mounted () {
        this.id = this.$route.query.id
        this.editType = this.$route.query.type
        if(this.id) {
            this.getElectricInfo()
            this.getElectricHis()
        }
        this.$getDic('chargeFeeType','select').then(res=>{ this.chargeFeeTypeDic = res; })
        this.$getDic('chargePayeeType','select').then(res=>{ this.chargePayeeTypeDic = res; })
    },
    methods:{
        getElectricInfo () {
            this.$Axios._post({
                url: this.$Config.apiUrl.electricInfo,
                method: "post",
                params: {
                    id: this.id
                }
            })
            .then(res=>{
                if (res.result.code == 0) {
                    this.form = {
                        ...res.result.data
                    }
                    let arr = []
                    // 遍历处理后台返回数据
                    this.form.stationAmmeterList.map((ite,idx)=>{
                        let obj = {
                            ...ite,
                            pid: this.form.id,
                            ratioId: ite.ammeterId,
                        }
                        arr.push(obj)
                    })
                    this.stationAmmeterList = arr
                } else {
                    this.$message.error(res.result.message)
                }
            })
            .catch(err=>{
                console.log(err);
            })
        },
        getElectricHis () {
            this.$Axios._post({
                url: this.$Config.apiUrl.electricHis,
                method: "post",
                params: {
                    id: this.id
                }
            })
            .then(res=>{
                if (res.result.code == 0) {
                    let arr = []
                    // 遍历处理后台返回数据
                    res.result.data.map((item,index)=>{
                        let listItem = JSON.parse(item.recordJson)
                        let tag = '-' + JSON.stringify(index) //每一个项目标识符
                        listItem.stationAmmeterList.map((ite,idx)=>{
                            let obj = {
                                ...ite,
                                chargeFeeType: listItem.chargeFeeType,
                                chargePayeeType: listItem.chargePayeeType,
                                pid: listItem.id+tag,
                                name: listItem.name,
                                propertyList: listItem.propertyList,
                                unitPrice: listItem.unitPrice || 0,
                                updateTime: listItem.updateTime,
                                nameId: listItem.id.id+tag,
                                propertyListId: listItem.id.id+tag,
                                unitPriceId: listItem.id.id+tag,
                                stationId: ite.stationId+tag,
                                ammeterId: ite.ammeterId+tag,
                                chargeFeeTypeId: item.id+tag,
                                chargePayeeTypeId: item.id+tag,
                                ratioId: ite.ammeterId+tag,
                            }
                            arr.push(obj)
                        })
                    })
                    this.tableData = arr
                } else {
                    this.$message.error(res.result.message)
                }
            })
            .catch(err=>{
                console.log(err);
            })
        },
        // 合并行
        mergeCols ({ row, column, rowIndex, columnIndex }) {
            return this.spanArr[rowIndex][columnIndex]
        },
        mergeColsStation ({ row, column, rowIndex, columnIndex }) {
            return this.spanArrStation[rowIndex][columnIndex]
        },
        editElectric () {
            this.$router.push({
                path: '/electric/electric-edit',
                query:{
                    id: this.id,
                }
            })
        },
        cancelSubmit () {
            this.$router.back()
            this.$store.dispatch("delView", this.$route);
        }
    },
}
</script>

<style lang="scss" scoped>
.send-user{
    background: #53A8FF;
    color: #ffffff;
    border-radius: 4px;
    padding: 0 10px;
    margin: 0 10px 10px 0;
}
.mt64{
    margin-top: 64px
}
.mt21{
    margin-top: 12px
}
.mr80{
    margin-right: 80px;
}
.c79{
    color: #797979
}
.title{
    width: 150px;
    margin-right: 16px;
}
.content{
    width: 240px
}
.tips{
    font-size: 12px;
    color: #909399;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    line-height: 22px;
}
</style>